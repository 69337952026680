@import "_mixins_and_vars.scss";

html{
  height: 100%;
}

body{
  min-height: 100%;
}

body{
  background-color: $color--red;
  position: relative;
  padding: 0;
  margin: 0;
}

.animation{
  position: absolute;
  width: 300px;
  height: 154px;
  left: 50%;
  margin-left: -150px;
  top: 50%;
  margin-top: -77px;

  img{
    display: block;
    width: 100%;
    height: auto;
  }

}

.animation--link{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
@media screen and (min-width: 480px){
  .animation{
    position: absolute;
    width: 410px;
    height: 210px;
    left: 50%;
    margin-left: -205px;
    top: 50%;
    margin-top: -120px;
  }
}
